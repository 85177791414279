import React from 'react'
import {graphql} from 'gatsby'
import {Layout} from '../components/Layout'
import {ZHelmet} from '../components/ZHelmet'
import {
  Stack,
  Cluster,
  Text,
  Box,
  Frame,
  Center,
  Grid,
} from '../common'
import {documentToReactComponents} from '@contentful/rich-text-react-renderer'
import {css} from '@emotion/core'
import {BlogFB} from '../common/icons/BlogFB'
import {BlogTwitter} from '../common/icons/BlogTwitter'
import {BlogDate, BlogItem} from '../pages/blogs'
import {BlogTile} from '../components/BlogTile'
import {BlogLinkedIn} from '../common/icons/BlogLinkedin'

type BlogPostData = {
  createdAt: string
  postSummary: string
  postTitle: string
  slug: string
  readingTimeInMins: string
  previewImage: {
    file: {
      url: string
    }
  }
  postContent: {
    json: any
  }
}

type BlogData = {}





const BlogPost = props => {
  const blog: BlogPostData = props.data.contentfulBlogs
  const allBlogs: BlogItem[] = props.data.allContentfulBlogs.nodes.filter(
    blog => blog.slug,
  )
  return (
    <Layout>
      <ZHelmet
        title={blog.postTitle}
        description={blog.postSummary}
        image={blog.previewImage.file.url}
        ogUrl={'https://zavronfinserv.com/blog/' + blog.slug}
      />
      <Box style={{paddingTop: 'var(--s2)'}}>
        <Center gutters="var(--s1)" maxWidth="var(--max-width)">
          <Stack space="var(--s1)">
            <Box>
              <Frame numerator={1} denominator={2}>
                <img
                  style={{borderRadius: 4}}
                  src={blog.previewImage.file.url}
                  alt={blog.postTitle}
                />
              </Frame>
            </Box>
            <Box>
              <BlogDate
                createdAt={blog.createdAt}
                readingTimeInMins={blog.readingTimeInMins}
              />
            </Box>
            <Box style={{flexGrow: 4, paddingBottom: 'var(--s2)'}}>
              <Stack space="var(--s2)">
                <Text variant="h1">{blog.postTitle}</Text>
                <Box
                  css={css`
                    h2 {
                      font-size: var(--h2);
                      margin: 2rem 0 1rem 0;
                    }
                    h3 {
                      font-size: var(--h3);
                      margin: 2rem 0 1rem 0;
                    }
                    p {
                      font-size: var(--p);
                      margin-bottom: 1rem;
                    }
                  `}>
                  {documentToReactComponents(blog.postContent.json)}
                </Box>
                <Stack space="var(--s0)">
                  <Text variant="p">Share on</Text>
                  <Cluster space="var(--s0)">
                    <a
                      target="_blank"
                      href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
                        'https://zavronfinserv.com/blog/' + blog.slug,
                      )}`}>
                      <BlogFB />
                    </a>
                    <a
                      target="_blank"
                      href={`https://twitter.com/intent/tweet?text=${encodeURIComponent(
                        'https://zavronfinserv.com/blog/' + blog.slug,
                      )}`}>
                      <BlogTwitter />
                    </a>
                    <a
                      target="_blank"
                      href={`https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(
                        'https://zavronfinserv.com/blog/' + blog.slug,
                      )}`}
                      // href={`https://www.linkedin.com/shareArticle?mini=true&${encodeURIComponent(
                      //   'https://zavronfinserv.com/blog/' + blog.slug,
                      // )}&title=${encodeURIComponent(
                      //   blog.postTitle,
                      // )}&summary=${encodeURIComponent(blog.postSummary)}`}
                    >

                      <BlogLinkedIn />
                    </a>
                  </Cluster>
                </Stack>
              </Stack>
            </Box>
            {allBlogs.length ? (
              <Box>
                <Stack space="var(--s-1)">
                  <Text variant="h4">YOU MIGHT ALSO LIKE</Text>
                  <Box>
                    <Grid gap="var(--s3)" min="300px">
                      {allBlogs.slice(0, 3).map(blogTileContent => (
                        <BlogTile blog={blogTileContent} />
                      ))}
                    </Grid>
                  </Box>
                </Stack>
              </Box>
            ) : null}
          </Stack>
        </Center>
      </Box>
    </Layout>
  )
}

export default BlogPost

export const query = graphql`
  query($slug: String!) {
    contentfulBlogs(slug: {eq: $slug}) {
      createdAt(formatString: "MMM D, yyyy")
      postSummary
      postTitle
      readingTimeInMins
      slug
      previewImage {
        file {
          url
        }
      }
      postContent {
        json
      }
    }
    allContentfulBlogs(
      sort: {fields: createdAt, order: DESC}
      filter: {node_locale: {eq: "en-US"}, slug: {ne: $slug}}
    ) {
      nodes {
        id
        postTitle
        createdAt(formatString: "MMM D, yyyy")
        postSummary
        previewImage {
          file {
            url
          }
        }
        readingTimeInMins
        slug
      }
    }
  }
`
