import React from 'react'

export function BlogLinkedIn() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="40"
      fill="none"
      viewBox="0 0 40 40">
      <circle cx="20" cy="20" r="20" fill="#001F79"></circle>
      <path
        fill="#fff"
        d="M12.983 15.196a2.188 2.188 0 100-4.376 2.188 2.188 0 000 4.376zM17.237 16.855v12.139h3.769V22.99c0-1.584.298-3.118 2.262-3.118 1.937 0 1.961 1.81 1.961 3.218v5.904H29v-6.657c0-3.27-.704-5.783-4.526-5.783-1.835 0-3.065 1.007-3.568 1.96h-.051v-1.66h-3.618zm-6.142 0h3.775v12.139h-3.775v-12.14z"></path>
    </svg>
  )
}
