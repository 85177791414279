import React from "react"

function BlogTwitter() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="40"
      fill="none"
      viewBox="0 0 40 40"
    >
      <circle cx="20" cy="20" r="20" fill="#001F79"></circle>
      <path
        fill="#fff"
        d="M27.633 15.997c.013.175.013.349.013.523 0 5.325-4.053 11.46-11.46 11.46-2.282 0-4.402-.66-6.186-1.808.324.037.636.05.973.05a8.07 8.07 0 005.001-1.721 4.037 4.037 0 01-3.767-2.793c.249.037.499.062.761.062.361 0 .724-.05 1.061-.137a4.027 4.027 0 01-3.23-3.953v-.05c.537.299 1.16.486 1.82.51a4.023 4.023 0 01-1.796-3.353c0-.748.199-1.434.548-2.032a11.457 11.457 0 008.306 4.215c-.062-.3-.1-.611-.1-.923a4.024 4.024 0 014.028-4.028c1.16 0 2.207.486 2.943 1.272a7.958 7.958 0 002.556-.973c-.3.93-.93 1.72-1.771 2.22a8.077 8.077 0 002.319-.624 8.647 8.647 0 01-2.019 2.083z"
      ></path>
    </svg>
  )
}

export { BlogTwitter }
