import React from "react"

function BlogFB() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="40"
      fill="none"
      viewBox="0 0 40 40"
    >
      <circle cx="20" cy="20" r="20" fill="#001F79"></circle>
      <path
        fill="#fff"
        d="M21.397 28.997v-8.196h2.765l.411-3.209h-3.176v-2.044c0-.926.258-1.56 1.587-1.56h1.684v-2.86c-.82-.089-1.643-.131-2.467-.128-2.444 0-4.122 1.492-4.122 4.231v2.355h-2.747v3.21h2.753v8.201h3.312z"
      ></path>
    </svg>
  )
}

export { BlogFB }
